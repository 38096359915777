import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { useNotifications } from 'context/notifications';
import cx from 'classnames';
import { useLanguage } from 'utils';
import * as paths from 'paths';
import { AuthWrapper } from 'components/common/auth';
import * as authApi from 'api/AuthApi';
import Rate from 'components/common/Rate';
import Title from 'components/common/auth/Title';
import { Input } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { Text, Field } from 'components/service';
import { Button } from 'components/kit';
import { validationSchema, initialValues } from './data';
import VIEVA_GB from 'assets/vieva-bg.png';
import VIEVA_LOGO from 'assets/vieva-logo.svg';

const Login = () => {
  const { push, location } = useHistory();
  const { t, i18n } = useTranslation();
  const { isRtl } = useLanguage();
  const notifications = useNotifications();
  const [isRegister] = useState(false);
  const { mutateAsync: authWithOptFn, isLoading } = useMutation(data => authApi.authWithOpt(data));
  const redirect = location.search.replace('?redirect=', '');

  const auth = data => {
    authWithOptFn(
      {
        email: data?.email?.toLowerCase(),
        usage: 'auth',
        user: {},
      },
      {
        onSuccess: () => {
          mixpanel.track('Login', {
            email: data?.email,
          });

          if (redirect) {
            push(`${paths.confirmationOTP}?redirect=${redirect}`, {
              email: data?.email?.toLowerCase(),
              isRegister,
            });
          } else {
            push(paths.confirmationOTP, {
              email: data?.email?.toLowerCase(),
              isRegister,
            });
          }
        },
        onError: ({ response: { data } }) => {
          for (let key of Object.keys(data)) {
            notifications.show(`${data[key]}`, 'error');
          }
        },
      },
    );
  };

  return (
    <>
      <AuthWrapper
        image={VIEVA_GB}
        logo={VIEVA_LOGO}
        lang={i18n.language}
        title={
          <Title
            title={
              <Text
                className="text-white text-center lg:text-4xl md:text-2xl font-semibold px-4"
                value="Create_culture_trust_empowerment"
              />
            }
            className="top-1/3"
          />
        }
        rate={
          <Rate
            title={
              <Text
                className="text-white text-xl font-semibold"
                value="Collaborateurs_accompagnes_sont_moyenne"
              />
            }
            className="top-2/4"
          />
        }
      >
        <div className={cx('w-10/12 sm:w-3/5', isRtl && 'text-right')}>
          <Text className="text-3xl mb-16" value={isRegister ? 'Sign_up' : 'Sign_in'} />

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async data => auth(data)}
          >
            {({ values, handleChange, errors, touched }) => (
              <Form>
                <Label title={t('Email_address')} htmlFor="email" className="mt-2 mb-10">
                  <Field
                    id="email"
                    type="email"
                    name="email"
                    value={values.email}
                    component={Input}
                    onChange={e => handleChange(e)}
                    error={errors.email}
                    touched={touched}
                  />
                </Label>

                <Button kind="primary" textColor="text-white" isSpinning={isLoading} full>
                  <Text value={isRegister ? 'Sign_up' : 'Sign_in'} />
                </Button>

                {/* {!isRegister && (
                  <>
                    <div className="flex items-center justify-between">
                      <hr className="border-1 border-gray-200 w-1/3" />
                      <Text
                        className="text-center text-sm text-gray-500 my-8"
                        value="Dont_have_account"
                      />
                      <hr className="border-1 border-gray-200 w-1/3" />
                    </div>

                    <Button
                      kind="secondary"
                      full
                      onClick={() => {
                        push(paths.registerUser);
                        // setIsRegister(true);
                      }}
                    >
                      <Text value="Get_started" />
                    </Button>
                  </>
                )} */}
              </Form>
            )}
          </Formik>
        </div>
      </AuthWrapper>
    </>
  );
};

export default Login;
